import React from "react";
import "./comparativa.scss";
import comparativa from "../../assets/img/comparativa.png";

const Comparativa = () => {
  return (
    <div className="grafica-comparativa">
      <h4>
        Cuando instalas paneles solares, fijas el precio de lo que vas a gastar
        máximo de Luz en los siguientes años.
      </h4>
      <h5 className="thiny">
        Nota. El costo de la luz sube 20% aprox. anualmente.{" "}
      </h5>
      <img
        className="image-comparativa"
        src={comparativa}
        alt="Comparativa de gastos y ahorro con paneles solares"
      />
    </div>
  );
};

export default Comparativa;
