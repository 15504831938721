import React, { useEffect, useRef, useState } from "react";
import "./header.scss";
import logotipoheader from "../../assets/img/logotipoheader.svg";
import relojdearena from "../../assets/img/relojdearena.svg";

const Header = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date("May 14 2021 00:00:00").getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        //eslint-disable-next-line
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };
  useEffect(() => {
    startTimer();
    return () => {
      //eslint-disable-next-line
      clearInterval(interval.current);
    };
  });
  return (
    <div className="header-component">
      <a href="/">
        <img
          className="logotipoheader"
          src={logotipoheader}
          alt={"Bolt Energy"}
        />
      </a>
      <h1>Haz que el pago de Luz sea una inversión y no un gasto. </h1>{" "}
      <div className="pago-inicial">
        <h2>Pago inicial: $0</h2>
        <h4>Beneficios fiscales GARANTIZADOS</h4>
      </div>
      <div className="reloj">
        <img src={relojdearena} alt={"Promocion finalizara en"} />
        <h4>LA PROMOCIÓN FINALIZARÁ EN :</h4>
      </div>
      <div className="component-counter">
        <div className="count-days">
          <div className="counter days">
            <h5>{timerDays}</h5>
          </div>
          <p>Días</p>
        </div>
        <div className="count-days">
          <div className="counter hours">
            <h5>{timerHours}</h5>
          </div>
          <p>Horas</p>
        </div>
        <div className="count-days">
          {" "}
          <div className="counter minutes">
            <h5>{timerMinutes}</h5>
          </div>
          <p>Min.</p>
        </div>{" "}
        <div className="count-days">
          <div className="counter seconds">
            <h5>{timerSeconds}</h5>
          </div>
          <p>Seg.</p>{" "}
        </div>
      </div>
    </div>
  );
};

export default Header;
