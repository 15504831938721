import React from "react";
import "./imagenesgrid.scss";

const Imagenesgrid = () => {
  return (
    <div className="imagenesgrid">
      <div className="imafe uno"></div>
      <div className="imafe dos"></div>
      <div className="imafe tres"></div>
      <div className="imafe cuatro"></div>
      <div className="imafe cinco"></div>
      <div className="imafe seis"></div>
      <div className="imafe siete"></div>
      <div className="imafe ocho"></div>
    </div>
  );
};

export default Imagenesgrid;
