import React from "react";
import "./buttonns.scss";
import whatsappvector from "../../assets/img/whatsappvector.svg";
import telefonovector from "../../assets/img/telefonovector.svg";

const Buttonns = () => {
  return (
    <div className="buttonz">
      <a href="https://wa.link/o6wmj1">
        {" "}
        <button>
          ENVIAR A WHATSAPP <img src={whatsappvector} alt="Boton whatsapp" />
        </button>
      </a>
      <a href="tel:8717699371">
        {" "}
        <button>
          LLAMAR <img src={telefonovector} alt="Boton telefono" />
        </button>
      </a>
    </div>
  );
};

export default Buttonns;
