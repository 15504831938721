import "./footer.scss";
import React from "react";
import footerface from "../../assets/img/footerface.png";
import footerinsta from "../../assets/img/footerinsta.png";
import footerlinkedin from "../../assets/img/footerlinkedin.png";
import footerlogotipo from "../../assets/img/footerlogotipo.svg";

const Footer = () => {
  return (
    <div className="footer-component">
      <a href="/">
        {" "}
        <img
          className="logo-footer-bold"
          src={footerlogotipo}
          alt={"Logotipo Bolt Energy"}
        />
      </a>
      <a href="/">
        <h5>AVISO DE PRIVACIDAD</h5>
      </a>
      <a href="tel:8717699371">
        {" "}
        <h4>+52 871 769 9371</h4>
      </a>
      <div className="fo-logos">
        <a href="https://www.facebook.com/BoltEnergy.Com.Mx/">
          {" "}
          <img
            className="footer-redes"
            src={footerface}
            alt={"Logotipo Facebook"}
          />
        </a>
        <a href="https://www.instagram.com/boltenergy.mx/?igshid=bjr3i5i4wbvt">
          <img
            className="footer-redes"
            src={footerinsta}
            alt={"Logotipo Instagram"}
          />
        </a>
        <a href="https://www.linkedin.com/company/bolt-energy-m%C3%A9xico/">
          {" "}
          <img
            className="footer-redes"
            src={footerlinkedin}
            alt={"Logotipo Linkedin"}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
