import React from "react";
import "./calendly.scss";

const Calendly = () => {
  return (
    <div className="calendly-local">
      <h3>
        Agenda una reunión en segundos, no te haremos perder el tiempo y te
        haremos ganar dinero.
      </h3>
      <div
        className="calendly-inline-widget calendly-inner"
        data-url="https://calendly.com/boltenergy-citas-express/consultoria-gratuita-de-como-ahorrar-con-paneles-solares-hoy-mismo"
      ></div>
    </div>
  );
};

export default Calendly;
