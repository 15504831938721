import React, { useEffect } from "react";
import Footer from "./components/footer/Footer";
import Todavia from "./components/todavia/Todavia";
import Inversion from "./components/inversion/Inversion";
import Proceso from "./components/proceso/Proceso";
import Imagenesgrid from "./components/imagenesgrid/Imagenesgrid";
import Ganar from "./components/ganar/Ganar";
import Beneficios from "./components/beneficios/Beneficios";
import Opinion from "./components/opinion/Opinion";
import Lista from "./components/lista/Lista";
import Header from "./components/header/Header";
import Comparativa from "./components/comparativa/Comparativa";
import Buttonns from "./components/buttonns/Buttonns";
import Whatsapp from "./components/whatsapp/Whatsapp";
import Clients from "./components/clients/Clients";
import Calendly from "./components/calendly/Calendly";
import ReactGa from "react-ga";

function App() {
  useEffect(() => {
    ReactGa.initialize("UA-175693789-1");
    ReactGa.pageview("contacto.boltenergy.com.mx");
  }, []);

  return (
    <>
      <Header />
      <Whatsapp />
      <Lista />
      <Beneficios />
      <Opinion />
      <Ganar />
      <Imagenesgrid />
      <Comparativa />
      <Proceso />
      <Inversion />
      <Todavia />
      <Clients />
      <Calendly />
      <Buttonns />
      <Footer />
    </>
  );
}

export default App;
