import React, { useState } from "react";
import "./lista.scss";
import HubspotForm from "react-hubspot-form";
import Gracias from "./../gracias/Gracias";
import ReactGa from "react-ga";

const Lista = () => {
  //aqui empieza el cambio
  const [enviado, setEnviado] = useState(false);
  const submitHandler = () => {
    setEnviado(true);
    ReactGa.event({
      category: "Cliente Potencial",
      action: "Envio del Formulario de contacto a Hubspot",
    });
  };

  return enviado ? (
    <Gracias />
  ) : (
    <div className="component-lista">
      <h3>
        Te llamaremos lo más rápido posible, cuidaremos tú tiempo y tú dinero.{" "}
      </h3>
      <div className="formulario">
        <HubspotForm
          className="color-white"
          portalId="8346334"
          formId="0b4addc0-3aa7-400b-a65f-4452bebd213b"
          onFormSubmitted={submitHandler}
          loading={<div className="cargando"> Cargando... </div>}
        />
      </div>
    </div>
  );
};

export default Lista;
