import React from "react";
import "./clients.scss";

const Clients = () => {
  return (
    <div className="clients-container">
      <div className="clients-title">
        <h4>ALGUNOS CLIENTES INDUSTRIALES Y COMERCIALES</h4>
        <h5 className="thiny">QUE HAN CONFIADO EN NOSOTROS</h5>
      </div>
      <div className="sliders-container">
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <span alt="" className="bolt1alfa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt11agropecuaria" />
            </div>
            <div className="slide">
              <span alt="" className="bolt4carmona" />
            </div>
            <div className="slide">
              <span alt="" className="bolt18ceo" />
            </div>
            <div className="slide">
              <span alt="" className="bolt21cfe" />
            </div>
            <div className="slide">
              <span alt="" className="bolt16crepe" />
            </div>
            <div className="slide">
              <span alt="" className="bolt8eljuego" />
            </div>
            <div className="slide">
              <span alt="" className="bolt2foresta" />
            </div>
            <div className="slide">
              <span alt="" className="bolt15gaston" />
            </div>
            <div className="slide">
              <span alt="" className="bolt3gloria" />
            </div>
            <div className="slide">
              <span alt="" className="bolt9lapaloma" />
            </div>
            <div className="slide">
              <span alt="" className="bolt1alfa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt11agropecuaria" />
            </div>
            <div className="slide">
              <span alt="" className="bolt4carmona" />
            </div>
            <div className="slide">
              <span alt="" className="bolt18ceo" />
            </div>
            <div className="slide">
              <span alt="" className="bolt21cfe" />
            </div>
            <div className="slide">
              <span alt="" className="bolt16crepe" />
            </div>
            <div className="slide">
              <span alt="" className="bolt8eljuego" />
            </div>
          </div>
        </div>
        <div className="slider">
          <div className="slide-track2">
            <div className="slide">
              <span alt="" className="bolt17madeira" />
            </div>
            <div className="slide">
              <span alt="" className="bolt5marsa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt13monterreal" />
            </div>
            <div className="slide">
              <span alt="" className="bolt10pamen" />
            </div>
            <div className="slide">
              <span alt="" className="bolt7probc" />
            </div>
            <div className="slide">
              <span alt="" className="bolt14qualitas" />
            </div>
            <div className="slide">
              <span alt="" className="bolt6villa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt19volkswagen" />
            </div>
            <div className="slide">
              <span alt="" className="bolt12enyog" />
            </div>
            <div className="slide">
              <span alt="" className="bolt22yog" />
            </div>
            <div className="slide">
              <span alt="" className="bolt17madeira" />
            </div>
            <div className="slide">
              <span alt="" className="bolt5marsa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt20martinez" />
            </div>
            <div className="slide">
              <span alt="" className="bolt13monterreal" />
            </div>
            <div className="slide">
              <span alt="" className="bolt10pamen" />
            </div>
            <div className="slide">
              <span alt="" className="bolt7probc" />
            </div>
            <div className="slide">
              <span alt="" className="bolt14qualitas" />
            </div>
            <div className="slide">
              <span alt="" className="bolt6villa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt19volkswagen" />
            </div>
            <div className="slide">
              <span alt="" className="bolt12enyog" />
            </div>
            <div className="slide">
              <span alt="" className="bolt22yog" />
            </div>
            <div className="slide">
              <span alt="" className="bolt17madeira" />
            </div>
            <div className="slide">
              <span alt="" className="bolt5marsa" />
            </div>
            <div className="slide">
              <span alt="" className="bolt20martinez" />
            </div>
            <div className="slide">
              <span alt="" className="bolt13monterreal" />
            </div>
            <div className="slide">
              <span alt="" className="bolt10pamen" />
            </div>
            <div className="slide">
              <span alt="" className="bolt7probc" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
