import React from "react";
import "./proceso.scss";
import cfe from "../../assets/img/cfe.svg";
import bolt from "../../assets/img/bolt.svg";
import panel from "../../assets/img/panel.svg";
import iconouno from "../../assets/img/iconouno.svg";
import iconodos from "../../assets/img/iconodos.svg";
import iconotres from "../../assets/img/iconotres.svg";
import iconocuatro from "../../assets/img/iconocuatro.svg";

const Proceso = () => {
  return (
    <div className="proceso">
      <h4>NUESTRO PROCESO</h4>
      <div className="proceso-elements">
        <div className="organization">
          <img
            className="icono-paso"
            src={cfe}
            alt={"Recibo de luz mayor a 3000"}
          />
          <img className="icono-check" src={iconouno} alt={"Primer paso"} />
          <h5>RECIBO DE LUZ MAYOR A $3,000</h5>
        </div>
        <div className="organization">
          <img
            className="icono-paso"
            src={bolt}
            alt={"Recibo de luz mayor a 3000"}
          />
          <img className="icono-check" src={iconodos} alt={"Segundo paso"} />
          <h5>VISITA DE BOLT ENERGY</h5>
        </div>
        <div className="organization">
          <img
            className="icono-paso"
            src={panel}
            alt={"Recibo de luz mayor a 3000"}
          />
          <img className="icono-check" src={iconotres} alt={"Tercer paso"} />
          <h5>INSTALACIÓN DE PANELES SOLARES</h5>
        </div>
        <div className="organization">
          <img
            className="icono-paso"
            src={cfe}
            alt={"Recibo de luz mayor a 3000"}
          />
          <img className="icono-check" src={iconocuatro} alt={"Cuarto paso"} />
          <h5>RECIBO DE LUZ DE $50</h5>
        </div>
      </div>
    </div>
  );
};

export default Proceso;
