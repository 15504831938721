import React from "react";
import "./ganar.scss";
import ganarbolt from "../../assets/img/ganarbolt.svg";

const Ganar = () => {
  return (
    <div className="ganar-component">
      <div className="blue left">
        <a href="/">
          {" "}
          <img src={ganarbolt} alt={"Logotipo Bolt Energy"} />
        </a>
        <h3 className="bigone">
          CADA UNO DE NOSOTROS QUEREMOS QUE GANES DINERO
        </h3>
        <h3 className="litleone">DEJANDO DE PAGAR LA CFE.</h3>
      </div>
      <div className="blue right"> </div>
    </div>
  );
};

export default Ganar;
