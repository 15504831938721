import React from "react";
import "./whatsapp.scss";
import whatsapp from "../../assets/img/whatsapp.png";

const Whatsapp = () => {
  return (
    <>
      <a className="float-whatsapp" href="https://wa.link/o6wmj1">
        {" "}
        <img
          className="imagen-whatsapp"
          target="_blank"
          src={whatsapp}
          alt="Icono Whatsapp"
        />
      </a>
    </>
  );
};

export default Whatsapp;
