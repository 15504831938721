import React from "react";
import "./gracias.scss";
import graciaslogotype from "../../assets/img/graciaslogotype.svg";

const Gracias = () => {
  return (
    <div className="gracias-component">
      <a href="https://contacto.boltenergy.com.mx">
        {" "}
        <img alt="logotipo Boltenergy" src={graciaslogotype} />
      </a>{" "}
      <h4>Gracias por escribirnos, en breve estaremos en contacto contigo. </h4>
    </div>
  );
};

export default Gracias;
