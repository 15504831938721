import React from "react";
import "./beneficios.scss";
import beneficios from "../../assets/img/beneficios.svg";
import servicio from "../../assets/img/servicio.svg";
import check from "../../assets/img/check.svg";

const Beneficios = () => {
  return (
    <div className="beneficios-component">
      <div className="image-component"></div>
      <div className="cards-component">
        <div className="beneficios-card primera">
          {" "}
          <div className="first-card">
            <div className="image-card">
              <img
                src={beneficios}
                alt={"Beneficios de paneles solares en la luz de tu hogar"}
              />
            </div>
            <h4>BENEFICIOS</h4>
          </div>
          <div className="inferior-card">
            <ul>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>Ahorro en tu pago mensual de luz</p>
              </li>
              <li>
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />{" "}
                <p>Disfruta de los beneficios fiscales desde el primer día.</p>
              </li>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />{" "}
                <p>No hay pago inicial.</p>
              </li>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>
                  {" "}
                  Te conviertes en dueño de
                  <br /> un activo al terminar tu <br />
                  contrato.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="beneficios-card segunda">
          <div className="first-card">
            <div className="image-card">
              <img
                src={servicio}
                alt={"Servicio completo con costos de instalación"}
              />
            </div>
            <h4>El SERVICIO INCLUYE</h4>
          </div>
          <div className="inferior-card">
            <ul>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>Todos los costos de instalación</p>
              </li>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>Todos los costos de mantenimiento</p>
              </li>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>Trámite de interconexión con CFE.</p>
              </li>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>La garantía de producción más alta del mercado.</p>
              </li>
              <li>
                {" "}
                <img
                  src={check}
                  alt={"Beneficios y cosas que incluye el servicio"}
                />
                <p>El costo del panel más bajo del mercado.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beneficios;
