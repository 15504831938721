import React from "react";
import "./todavia.scss";

const Todavia = () => {
  return (
    <div className="first-compo-todavia">
      <div className="compo-todavia">
        <div className="compo-class todavia-title">
          <h3>
            Si todavía no estas seguro de la cantidad de dinero que pudieras
            ganar
          </h3>
        </div>
        <div className="compo-class todavia-simulador">
          <h4>Visita nuestro increíble simulador.</h4>
          <a href="https://simulador.boltenergy.com.mx/">
            <button onClick={() => console.log("hola")}>
              IR AL SIMULADOR{" "}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Todavia;
