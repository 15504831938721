import React from "react";
import "./opinion.scss";

const Opinion = () => {
  return (
    <div className="opinion-component">
      <h4>OPINIONES DE NUESTROS CLIENTES </h4>
      <div className="opinion-card">
        <div className="individual">
          <div className="datos">
            <div className="img juan"></div>
            <p>Juan Luis Ramirez</p>
          </div>
          <p>Excelente servicio, Estoy muy satisfecho.</p>
        </div>
        <div className="individual">
          <div className="datos">
            <div className="img luisa"></div>
            <p>Luisa Resendiz</p>
          </div>
          <p>
            Es una inversión que lo vale,estoy viendo el ahorro en electricidad.
          </p>
        </div>
        <div className="individual">
          <div className="datos">
            <div className="img carlos"></div>
            <p>Carlos Quintana</p>
          </div>
          <p>Realmente estoy viendo el ahorro en mi gasto de luz.</p>
        </div>
        <div className="individual">
          <div className="datos">
            <div className="img alejandra"></div>
            <p>Alejandra Torres</p>
          </div>
          <p>
            Una gran herramienta para los que tenemos un negocio, el gasto en
            electricidad es considerable.
          </p>
        </div>
      </div>

      <h4 className="notmargin">
        Estamos seguros de lo que hacemos, por eso EN esta promoción, nosotros
        pagamos tú siguiente recibo de CFE
      </h4>
      <h3> hasta $10,000</h3>
      <iframe
        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FBoltEnergy.Com.Mx%2Fvideos%2F217462566804713%2F&show_text=false&width=560"
        className="frame-component"
        title="Video player facebook"
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay;
        clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default Opinion;
