import React from "react";
import "./inversion.scss";
import inversionboltenergy from "../../assets/img/inversionboltenergy.svg";

const Inversion = () => {
  return (
    <div className="component-inversion">
      <div className="component-second">
        <a href="/">
          {" "}
          <img src={inversionboltenergy} alt={"Logotipo Bolt Energy"} />
        </a>
        <h4 className="not-child">
          Vas a tener un activo sin <br />
          gastar extra a lo que ya gastas,
        </h4>
        <h3 className="not-child">
          TÚ INVERSIÓN VA A MULTIPLICARSE AL MENOS 6 VECES{" "}
        </h3>
        <h3 className="not-high">
          vas a multiplicar por al menos 6 veces tú dinero
        </h3>
        <h4> y no tienes que poner nada de enganche.</h4>
      </div>
    </div>
  );
};

export default Inversion;
